<template>
  <lf-card
    class="pt-6 mb-8 bg-white pb-5"
    has-header-divider
    :has-header-margin="false"
    :has-padding="false"
    data-cy="funder-card"
  >
    <template #header>
      <div class="px-6 flex justify-between w-full">
        <div class="flex space-x-3 items-center">
          <img v-if="logoUrl" :src="logoUrl" alt="logo" class="h-10 w-10" />
          <lf-h3>
            {{ sectionTitle }}
          </lf-h3>
        </div>
        <div
          v-if="groupedOffers.offer_details.length > 1 && !alwaysShowAll"
          class="w-[35vw]"
        >
          <lf-scrollable-menu
            class="justify-end flex"
            :styling-options="{
              offsetRightClasses: '-top-2 -right-12',
              offsetLeftClasses: '-top-2 -left-12',
              buttonsTransparent: true
            }"
          >
            <button
              class="border uppercase rounded-2xl py-1 px-2 font-medium text-xs mx-0.5"
              :class="
                selectedOfferIndex === -1
                  ? 'bg-blue-100/60 text-primary border-primary'
                  : 'border-gray-200'
              "
              @click="selectedOfferIndex = -1"
            >
              {{ $t("COMMON.ALL") }}
            </button>
            <button
              v-for="(offerDetails, index) in groupedOffers.offer_details"
              :key="offerDetails.id"
              class="border uppercase rounded-2xl py-1 px-2 font-medium text-xs mx-0.5"
              :class="
                index === selectedOfferIndex
                  ? 'bg-blue-100/60 text-primary border-primary'
                  : 'border-gray-200'
              "
              @click="selectedOfferIndex = index"
            >
              {{ $t("COMMON.OFFER") }} {{ index + 1 }}
            </button>
          </lf-scrollable-menu>
        </div>
      </div>
    </template>
    <div class="overflow-x-auto">
      <div
        v-if="groupedOffers.offer_details.length"
        class="inline-block min-w-full"
      >
        <div v-if="selectedOfferIndex === -1" class="flex flex-col">
          <offer-line-item
            v-for="(offerDetails, index) in groupedOffers.offer_details"
            :class="{
              'border-t': index !== 0,
              'border-b': index === groupedOffers.offer_details.length - 1
            }"
            :key="offerDetails.id"
            :offer-detail="offerDetails"
            :funder="groupedOffers.funder"
            :placement="offerDetails.placement"
            :is-closing="isClosing"
            :is-funded="isFunded"
            :single-select="singleSelect"
            :multi-select="multiSelect"
            :select-one-key="selectOneKey"
            :select-details-provider="selectDetailsProvider"
            :view-only="viewOnly"
            class="flex-shrink-0"
            :is-row-clickable="isRowClickable"
            :is-selected="selectedOffers?.includes(offerDetails)"
            @selection-change="
              (offer, selected) =>
                $emit('updateSelectedOffers', offer, selected)
            "
          />
        </div>
        <div v-else>
          <offer-line-item
            :offer-detail="groupedOffers.offer_details[selectedOfferIndex]"
            :funder="groupedOffers.funder"
            :placement="
              groupedOffers.offer_details[selectedOfferIndex].placement
            "
            :is-row-clickable="isRowClickable"
            :is-closing="isClosing"
            :is-funded="isFunded"
            :multi-select="multiSelect"
            :single-select="singleSelect"
            :select-one-key="selectOneKey"
            :select-details-provider="selectDetailsProvider"
            :view-only="viewOnly"
            :is-selected="
              selectedOffers?.includes(
                groupedOffers.offer_details[selectedOfferIndex]
              )
            "
            class="flex-shrink-0 border-b"
            @selection-change="
              (offer, selected) =>
                $emit('updateSelectedOffers', offer, selected)
            "
          />
        </div>
      </div>
      <div v-else class="text-center p-2">
        {{ $t("OFFER_CUSTOMISATION.OFFERS_EMPTY") }}
      </div>
    </div>
  </lf-card>
</template>
<script setup lang="ts" generic="K extends IOffer">
import { computed, ref } from "vue";
import type { GroupedOffer } from "@/models/offers";
import OfferLineItem from "@/components/deals/workflow/OfferStage/WorkflowLive/OfferLineItem.vue";
import type { SelectDetailsProvider } from "@/models/common";
import type { IOffer } from "@/models/funders";
import { useI18n } from "vue-i18n";
import LfScrollableMenu from "@/components/ui/LfScrollableMenu.vue";

const props = defineProps<{
  groupedOffers: GroupedOffer<K>;
  soleFunder: boolean;
  isClosing?: boolean;
  isFunded?: boolean;
  isRowClickable?: boolean;
  multiSelect?: boolean;
  singleSelect?: boolean;
  selectOneKey?: keyof K;
  selectDetailsProvider?: SelectDetailsProvider;
  includeProductInTitle?: boolean;
  title?: string;
  alwaysShowAll?: boolean;
  viewOnly?: boolean;
  selectedOffers?: IOffer[];
}>();

defineEmits<{
  updateSelectedOffers: [IOffer, boolean | string];
}>();

const { t } = useI18n();

const selectedOfferIndex = ref(-1);

const logoUrl = computed(() => {
  return props.groupedOffers.funder?.logo?.file_url;
});

const sectionTitle = computed(() => {
  if (props.title) {
    return props.title;
  }
  let title = props.groupedOffers.funder?.full_name ?? t("COMMON.FUNDER");
  if (props.includeProductInTitle) {
    title += " ● " + props.groupedOffers.offer_details[0].product.name;
  }
  return title;
});
</script>
